<template>
  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!--            <label>Show</label>-->
            <!--            <v-select-->
            <!--              v-model="perPage"-->
            <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
            <!--              :options="perPageOptions"-->
            <!--              :clearable="false"-->
            <!--              class="per-page-selector d-inline-block mx-50"-->
            <!--            />-->
            <!--            <label>entries</label>-->
          </b-col>

          <!-- Search -->
          <b-col
            class="offset-lg-1"
            cols="12"
            md="6"
            lg="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="userData.role.code === 'su' || userData.role.code === 'sa'"
                variant="primary"
                :to="{ name: 'landing-pages-create' }"
                class="d-flex"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span class="text-nowrap">Create Landing Page</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <!--      <div class="d-flex justify-content-center">-->
      <!--        <b-spinner-->
      <!--          v-show="totalForms === null"-->
      <!--          label="Loading..."-->
      <!--          :variant="'primary'"-->
      <!--        />-->
      <!--      </div>-->
      <b-overlay
        :show="(totalForms === null) || (userData === null) ? true : false"
        variant="light"
        :opacity="0.7"
        blur="2px"
        rounded="sm"
      >
        <b-table
          ref="refFormListTable"
          class="position-relative"
          :items="getLandingPages"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: description -->
          <!--        <template #cell(description)="data">-->
          <!--          <div class="text-nowrap">-->
          <!--            <span class="align-text-top">{{ data.item.description|truncate(15, '...') }}</span>-->
          <!--          </div>-->
          <!--        </template>-->
          <!-- Column: start date exp. -->
          <!-- Column: finish date exp. -->
          <template #cell(campaign)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="userData.role.code === 'su' || userData.role.code === 'sa'"
                :to="{ name: 'landing-pages-edit', params: { id: data.item.id } }"
              >
                {{ data.item.title }}
              </b-link>
              <div v-else>
                {{ data.item.title }}
              </div>
              <div>
                <b-link
                  :to="{ name: 'landing-pages-display-by-company-slug', params: { company: formatCompanySlug(data.item.creator.company.name), slug: data.item.slug } }"
                  target="_blank"
                  class="font-small-3"
                >
                  https://tracebit.net/{{ formatCompanySlug(data.item.creator.company.name) }}/{{ data.item.slug }}
                </b-link>
              </div>
            </b-media>
          </template>
          <template #cell(period)="data">
            <div class="text-nowrap">
              <span class="align-text-top">{{ data.item.expired_start_date| moment('D MMM YYYY') }} - {{ data.item.expired_finish_date| moment('D MMM YYYY') }}</span>
            </div>
          </template>
          <!-- Column: finish date exp. -->
          <template #cell(status)="data">
            <div class="text-nowrap">
              <span
                v-if="data.item.salesperson_lists_count > 0 && new Date() >= new Date(data.item.expired_start_date) && new Date() <= new Date(data.item.expired_finish_date)"
                class="align-text-top text-success font-weight-bold"
              >
                Active
              </span>
              <span
                v-else
                class="align-text-top text-danger font-weight-bold"
              >
                Inactive
              </span>
            </div>
            <div
              v-if="data.item.salesperson_lists_count <= 0"
              class="font-small-2"
            >
              <span class="align-text-bottom text-danger">(Please add Salesperson to list)</span>
            </div>
            <div
              v-if="new Date() < new Date(data.item.expired_start_date) || new Date() > new Date(data.item.expired_finish_date)"
              class="font-small-2"
            >
              <span class="align-text-bottom text-danger">(Expired period)</span>
            </div>
          </template>
          <!-- Column: salesperson goal deal -->
          <template #cell(sales_goal_deal)="data">
            <div
              v-if="data.item.form_inputs_count === null || data.item.submission_goal === null"
              class="text-nowrap"
            >
              <span class="align-text-top">N/A</span>
            </div>
            <div
              v-else
              class="text-nowrap"
            >
              <span class="align-text-top">
                {{ ((data.item.form_inputs_count===null ? 0 : data.item.form_inputs_count) / (data.item.submission_goal===null ? 0 : data.item.submission_goal))*100 | formatNumber }} %
                ({{ data.item.form_inputs_count }}/{{ data.item.submission_goal }})
              </span>
            </div>
          </template>
          <!-- Column: submission goal -->
          <template #cell(submission_goal)="data">
            <div
              v-if="data.item.sales_goal_deal === null"
              class="text-nowrap"
            >
              <span class="align-text-top">{{ data.item.submission_goal }}</span>
            </div>
            <div
              v-else
              class="text-nowrap"
            >
              <span class="align-text-top">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.bottom="data.item.total_deal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') +' / '+ data.item.sales_goal_deal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')"
                  variant="flat-primary"
                  size="md"
                  class="p-0"
                >
                  {{ (data.item.total_deal_price / data.item.sales_goal_deal)*100 | formatNumber }} %
                </b-button>
              </span>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="userData.role.code === 'su' || userData.role.code === 'sa'"
                :to="{ name: 'landing-pages-edit', params: { id: data.item.id } }"
              >
                <feather-icon
                  icon="EditIcon"
                />
                <span class="align-middle ml-50">Edit Landing Page</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userData.role.code === 'su'"
                :to="{ name: 'landing-pages-display-by-slug', params: { slug: data.item.uncount_slug } }"
                target="_blank"
              >
                <feather-icon
                  icon="PlusIcon"
                />
                <span class="align-middle ml-50">Add Leads Manually</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userData.role.code === 'su'"
                @click="directToSalesperson(data.item.uuid)"
              >
                <!-- :to="{ name: 'leads-landing-page', params: { uuid: data.item.uuid } }" -->
                <feather-icon
                  icon="PlusIcon"
                />
                <span class="align-middle ml-50">Add Salesperson</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userData.role.code === 'su' || userData.role.code === 'sa'"
                @click="deleteForm(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <div
          v-if="dataMeta.of > perPage"
          class="mx-2 mb-2"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalForms"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
// import Vue from 'vue'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, VBTooltip, BOverlay,
  BDropdown, BDropdownItem, BPagination, BLink, BMedia,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { onUnmounted } from '@vue/composition-api'
// import { avatarText } from '@core/utils/filter'
import useLandingList from './useLandingList'
import landingPageStoreModule from '../landingPageStoreModule'

const numeral = require('numeral')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BLink,
    BMedia,
    BOverlay,
  },
  setup() {
    const FORM_APP_STORE_MODULE_NAME = 'menu-landing-page'

    // Register module
    if (!store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.registerModule(FORM_APP_STORE_MODULE_NAME, landingPageStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(FORM_APP_STORE_MODULE_NAME)
    })

    const {
      getLandingPages,
      tableColumns,
      perPage,
      currentPage,
      totalForms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFormListTable,
      userData,
      refetchData,
    } = useLandingList()

    return {
      getLandingPages,
      tableColumns,
      perPage,
      currentPage,
      totalForms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFormListTable,
      userData,
      refetchData,

      // Filter
      // avatarText,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) { return text.substring(0, length) + suffix }
      return text
    },
    formatNumber(value) {
      return numeral(value).format('0.00')
    },
  },
  data() {
    return {
    }
  },
  created() {
  },
  // metaInfo() {
  //   return {
  //     title: 'LandingPage - Epiloge',
  //   }
  // },
  methods: {
    formatCompanySlug(companyName){
      return companyName.replace(/\W+/g, ' ').replace(/\s/g, '-').toLowerCase()
    },
    deleteForm(formId) {
      this.$swal({
        title: 'Are you sure to delete ?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('menu-landing-page/removeLandingPage', { formId })
            .then(response => {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              if (error.response !== undefined){
                this.$swal({
                  icon: 'error',
                  title: 'Delete action failed!',
                  text: error.response.data.message,
                })
              }
            })
        }
      })
    },
    directToSalesperson(lpUuid) {
      this.$store.state.leadTabs = 'salesperson'
      this.$router.push({ name: 'leads-landing-page', params: { uuid: lpUuid } })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
